<template>
  <div
    class="round"
    :class="{
      main: type === 1,
      secondary: type === 2,
      thirdary: type === 3
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    type: Number
  }
};
</script>
<style lang="scss" scoped>
.round {
  border-radius: 50%;
  padding: 2px;

  > * {
    width: 36px;
    height: 36px;
  }

  &.main {
    background-color: $main-color;

    &:hover {
      background-color: $main-color-light;
    }
  }
  &.secondary {
    background-color: $second-color !important;

    &:hover {
      background-color: $second-color-light !important;
    }
  }
  &.thirdary {
    background-color: $third-color !important;

    &:hover {
      background-color: $third-color-light !important;
    }
  }
}
</style>
