<template>
  <v-dialog
    v-if="isOpen && items.length"
    v-model="isOpen"
    class="action-dialog"
    max-width="50%"
    persistent
  >
    <v-card>
      <v-card-title>
        <h2 class="action-dialog__description">{{ description }}</h2>
      </v-card-title>
      <v-card-text v-if="description">
        <v-select
          class="action-dialog__items"
          v-model="selectedValue"
          :items="items"
          :label="itemsLabel"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">{{
          translations.labels.dialog_cancel
        }}</v-btn>
        <v-btn color="green" outlined @click="confirm">{{
          translations.labels.dialog_confirm
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";
import ButtonBase from "@/components/dom/ButtonBase";

@Component({
  components: {
    ButtonBase,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    description: String,
    itemsLabel: String,
    items: {
      type: Array,
      default: () => [],
    },
    onConfirm: Function,
  },
})
export default class ActionDialog extends mixins(Translations) {
  selectedValue = "";

  close() {
    this.selectedValue = "";
    this.$emit("update:isOpen", false);
  }

  confirm() {
    if (!this.selectedValue) {
      alert(this.translations.labels.table_action_selectAnElement);
      return;
    }

    const selectedItem = this.items.filter(
      (item) => item.value == this.selectedValue
    )[0];
    this.onConfirm(this.selectedValue, selectedItem.text);
    this.close();
  }
}
</script>

<style lang="scss" scoped>
</style>