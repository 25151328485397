<template>
  <div class="table-action">
    <div class="text-left" v-if="type == 'boolean'">
      <v-icon
        :title="value ? 'True' : 'False'"
        :color="value ? 'green' : 'red darken-2'"
        class="pl-12"
      >
        {{ value ? trueIcon : falseIcon }}
      </v-icon>
    </div>
    <div class="text-left" v-if="type == 'switch'">
      <label v-if="isVisible" class="switch">
        <input type="checkbox" :checked="value" @click="onClick" />
        <span class="slider round"></span>
      </label>
    </div>
    <div v-if="type == 'action'">
      <tr>
        <td>
          <span v-if="isVisible" class="action-button" @click="onClick">
            <Round :type="buttonType">
              <v-icon dark :color="iconColor">{{ icon }}</v-icon>
            </Round>
          </span>
        </td>
      </tr>
    </div>
    <div v-if="type == 'link'">
      <tr>
        <td>
          <router-link :to="url">
            <Round :type="buttonType">
              <v-icon dark :color="iconColor">{{ icon }}</v-icon>
            </Round>
          </router-link>
        </td>
      </tr>
    </div>
    <div v-if="type == 'image'">
      <div>
        <img class="icon" :src="image" v-if="isVisible" />
        <span v-else>---</span>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import Component from "vue-class-component";
import Round from "@/components/dom/Round";

@Component({
  components: {
    Round,
  },
  props: {
    url: String,
    image: {
      type: String,
      default: undefined,
    },
    buttonType: {
      type: Number,
      default: 1,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: "mdi-cursor-default-outline",
    },
    trueIcon: {
      type: String,
      default: "mdi-check-outline",
    },
    falseIcon: {
      type: String,
      default: "mdi-close-outline",
    },
    type: String,
    value: null,
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
})
export default class TableAction extends Vue {
  search = "";
  onClick() {
    this.$emit("action");
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
