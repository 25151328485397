var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-list"},[(_vm.isReady)?_c('div',{staticClass:"table-panel"},[_c('div',{staticClass:"panel-body"},[_c('Container',[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"actions-container"},[(_vm.bulkActionsVisible)?_c('div',{staticClass:"bulk-actions-container"},[_c('v-select',{staticClass:"bulk-actions",attrs:{"items":_vm.bulkActionsOptions,"label":_vm.translations.labels.table_bulk_label},model:{value:(_vm.selectedBulkAction),callback:function ($$v) {_vm.selectedBulkAction=$$v},expression:"selectedBulkAction"}}),_c('ButtonBase',{attrs:{"text":_vm.translations.labels.table_bulk_apply,"type":1,"small":true,"on-click":_vm.applyBulkActions}})],1):_vm._e(),(_vm.filtersVisible)?_c('div',{staticClass:"filters-container"},[_c('div',{staticClass:"filters-selection"},[_c('v-select',{staticClass:"filters",attrs:{"items":_vm.filtersOptions,"label":_vm.translations.labels.table_filters_label},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}}),_c('ButtonBase',{attrs:{"text":_vm.translations.labels.table_filters_apply,"type":1,"small":true,"on-click":_vm.applyFilters}})],1),_c('div',{staticClass:"active-filters"},_vm._l((_vm.activeFiltersGroups),function(chipGroup,index){return _c('div',{key:("filterGroup" + index),staticClass:"group-filter"},[_c('span',{staticClass:"group-filter-name"},[_vm._v(_vm._s(chipGroup)+":")]),_vm._l((_vm.activeFilters.filter(
                        function (f) { return f.group == chipGroup; }
                      )),function(chipFilter,index){return _c('v-chip',{key:("filterChip" + index),staticClass:"chip-filter",attrs:{"close":""},on:{"click:close":function($event){return _vm.removeFromActiveFilters(chipFilter)}}},[_vm._v(" "+_vm._s(chipFilter.text)+" ")])})],2)}),0)]):_vm._e()]),(_vm.searchVisible)?_c('div',{staticClass:"search-container"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.translations.labels.table_search,"single-line":"","hide-details":""},on:{"input":_vm.updateSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()]),_c('v-data-table',{directives:[{name:"sortable",rawName:"v-sortable",value:(_vm.sortable),expression:"sortable"}],attrs:{"headers":_vm.headers,"items":_vm.itemsFiltered,"search":_vm.search,"options":_vm.tableOptions,"items-per-page":_vm.itemsPerPage,"hide-default-footer":_vm.hideFooter,"footer-props":_vm.footerProps,"show-select":_vm.tableMultiselect,"server-items-length":_vm.sspLength},on:{"sorted":_vm.onSortComplete,"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(value,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),_vm._l((_vm.links),function(link){return {key:("item." + (link.value)),fn:function(ref){
                      var item = ref.item;
return [_c('TableAction',{key:link.value,attrs:{"type":"link","icon":link.icon,"iconColor":link.iconColor,"url":_vm.formatValue(link, item),"buttonType":link.buttonType}})]}}}),_vm._l((_vm.actions),function(action){return {key:("item." + (action.value)),fn:function(ref){
                      var item = ref.item;
return [(_vm.showAction(action, item))?_c('TableAction',{key:action.value,attrs:{"type":"action","icon":action.icon,"iconColor":action.iconColor,"isVisible":_vm.isVisible(action, item),"buttonType":action.buttonType},on:{"action":function($event){return _vm.onAction(action, item)}}}):_vm._e()]}}}),_vm._l((_vm.multipleActions),function(multipleAction){return {key:("item." + (multipleAction.value)),fn:function(ref){
                      var item = ref.item;
return [_c('div',{key:multipleAction.value,staticClass:"multiple-actions-container"},_vm._l((multipleAction.actions),function(action){return _c('div',{key:action.value},[(_vm.showAction(action, item))?_c('TableAction',{key:action.value,attrs:{"type":"action","icon":action.icon,"iconColor":action.iconColor,"isVisible":_vm.isVisible(action, item),"buttonType":action.buttonType},on:{"action":function($event){return _vm.onAction(action, item)}}}):_vm._e()],1)}),0)]}}}),_vm._l((_vm.sliders),function(slider){return {key:("item." + (slider.value)),fn:function(ref){
                      var item = ref.item;
return [_c('TableAction',{key:slider.value,attrs:{"type":"switch","isVisible":_vm.isVisible(slider, item),"value":slider.itemValue(item)},on:{"action":function($event){return _vm.onAction(slider, item)}}})]}}}),_vm._l((_vm.booleans),function(boolean){return {key:("item." + (boolean.value)),fn:function(ref){
                      var item = ref.item;
return [_c('TableAction',{key:boolean.value,attrs:{"type":"boolean","trueIcon":boolean.trueIcon,"falseIcon":boolean.falseIcon,"value":boolean.itemValue(item)}})]}}}),_vm._l((_vm.images),function(image){return {key:("item." + (image.value)),fn:function(ref){
                      var item = ref.item;
return [_c('TableAction',{key:image.value,attrs:{"type":"image","image":image.itemValue(item),"isVisible":_vm.isVisible(image, item)}})]}}}),_vm._l((_vm.dates),function(date){return {key:("item." + (date.value)),fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(date, item, false))+" ")]}}}),_vm._l((_vm.datetimes),function(datetime){return {key:("item." + (datetime.value)),fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(datetime, item, true))+" ")]}}}),_vm._l((_vm.textareas),function(textarea){return {key:("item." + (textarea.value)),fn:function(ref){
                      var item = ref.item;
return [_c('div',{key:textarea.id,staticClass:"table-list__textarea-cell"},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","no-resize":"","rows":"1","append-outer-icon":item.icon || 'mdi-none',"hint":item.hint,"success":!!item.success,"error":!!item.error,"error-messages":item.error},on:{"change":function($event){return _vm.onTextareaChange(textarea, item)}},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1)]}}}),_vm._l((_vm.customs),function(custom){return {key:("item." + (custom.value)),fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(custom.itemValue(item))+" ")]}}})],null,true),model:{value:(_vm.selectedTableItems),callback:function ($$v) {_vm.selectedTableItems=$$v},expression:"selectedTableItems"}})],1)],1)],1),_c('ActionDialog',{attrs:{"is-open":_vm.dialog.show,"description":_vm.dialog.description,"items":_vm.dialog.items,"items-label":_vm.dialog.itemsLabel,"onConfirm":_vm.dialog.onConfirm},on:{"update:isOpen":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:is-open":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1):_c('div',[_c('Container',[_c('div',{staticClass:"wrapper-loader"},[_c('Loader',{attrs:{"width":"100","height":"100"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }