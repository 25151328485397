<template>
  <div class="table-list">
    <div v-if="isReady" class="table-panel">
      <div class="panel-body">
        <Container>
          <v-card>
            <v-card-title>
              <div class="actions-container">
                <div v-if="bulkActionsVisible" class="bulk-actions-container">
                  <v-select
                    class="bulk-actions"
                    v-model="selectedBulkAction"
                    :items="bulkActionsOptions"
                    :label="translations.labels.table_bulk_label"
                  ></v-select>

                  <ButtonBase
                    :text="translations.labels.table_bulk_apply"
                    :type="1"
                    :small="true"
                    :on-click="applyBulkActions"
                  />
                </div>

                <div v-if="filtersVisible" class="filters-container">
                  <div class="filters-selection">
                    <v-select
                      class="filters"
                      v-model="selectedFilter"
                      :items="filtersOptions"
                      :label="translations.labels.table_filters_label"
                    ></v-select>

                    <ButtonBase
                      :text="translations.labels.table_filters_apply"
                      :type="1"
                      :small="true"
                      :on-click="applyFilters"
                    />
                  </div>
                  <div class="active-filters">
                    <div
                      v-for="(chipGroup, index) in activeFiltersGroups"
                      :key="`filterGroup${index}`"
                      class="group-filter"
                    >
                      <span class="group-filter-name">{{ chipGroup }}:</span>
                      <v-chip
                        v-for="(chipFilter, index) in activeFilters.filter(
                          (f) => f.group == chipGroup
                        )"
                        :key="`filterChip${index}`"
                        class="chip-filter"
                        close
                        @click:close="removeFromActiveFilters(chipFilter)"
                      >
                        {{ chipFilter.text }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="searchVisible" class="search-container">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="translations.labels.table_search"
                  single-line
                  hide-details
                  @input="updateSearch"
                >
                </v-text-field>
              </div>
            </v-card-title>

            <v-data-table
              v-model="selectedTableItems"
              :headers="headers"
              :items="itemsFiltered"
              :search="search"
              v-sortable="sortable"
              @sorted="onSortComplete"
              :options.sync="tableOptions"
              :items-per-page="itemsPerPage"
              :hide-default-footer="hideFooter"
              :footer-props="footerProps"
              :show-select="tableMultiselect"
              :server-items-length="sspLength"
            >
              <template
                v-for="(value, name) in $scopedSlots"
                v-slot:[name]="data"
              >
                <slot :name="name" v-bind="data"></slot>
              </template>

              <template
                v-for="link in links"
                v-slot:[`item.${link.value}`]="{ item }"
              >
                <TableAction
                  :key="link.value"
                  type="link"
                  :icon="link.icon"
                  :iconColor="link.iconColor"
                  :url="formatValue(link, item)"
                  :buttonType="link.buttonType"
                />
              </template>
              <template
                v-for="action in actions"
                v-slot:[`item.${action.value}`]="{ item }"
              >
                <TableAction
                  v-if="showAction(action, item)"
                  :key="action.value"
                  type="action"
                  :icon="action.icon"
                  :iconColor="action.iconColor"
                  @action="onAction(action, item)"
                  :isVisible="isVisible(action, item)"
                  :buttonType="action.buttonType"
                />
              </template>
              <template
                v-for="multipleAction in multipleActions"
                v-slot:[`item.${multipleAction.value}`]="{ item }"
              >
                <div class="multiple-actions-container" :key="multipleAction.value">
                  <div v-for="action in multipleAction.actions" :key="action.value">
                    <TableAction
                      v-if="showAction(action, item)"
                      :key="action.value"
                      type="action"
                      :icon="action.icon"
                      :iconColor="action.iconColor"
                      @action="onAction(action, item)"
                      :isVisible="isVisible(action, item)"
                      :buttonType="action.buttonType"
                    />
                  </div>
                </div>
              </template>
              <template
                v-for="slider in sliders"
                v-slot:[`item.${slider.value}`]="{ item }"
              >
                <TableAction
                  :key="slider.value"
                  type="switch"
                  @action="onAction(slider, item)"
                  :isVisible="isVisible(slider, item)"
                  :value="slider.itemValue(item)"
                />
              </template>
              <template
                v-for="boolean in booleans"
                v-slot:[`item.${boolean.value}`]="{ item }"
              >
                <TableAction
                  :key="boolean.value"
                  type="boolean"
                  :trueIcon="boolean.trueIcon"
                  :falseIcon="boolean.falseIcon"
                  :value="boolean.itemValue(item)"
                />
              </template>
              <template
                v-for="image in images"
                v-slot:[`item.${image.value}`]="{ item }"
              >
                <TableAction
                  :key="image.value"
                  type="image"
                  :image="image.itemValue(item)"
                  :isVisible="isVisible(image, item)"
                />
              </template>
              <template
                v-for="date in dates"
                v-slot:[`item.${date.value}`]="{ item }"
              >
                {{ formatDate(date, item, false) }}
              </template>
              <template
                v-for="datetime in datetimes"
                v-slot:[`item.${datetime.value}`]="{ item }"
              >
                {{ formatDate(datetime, item, true) }}
              </template>
              <template
                v-for="textarea in textareas"
                v-slot:[`item.${textarea.value}`]="{ item }"
              >
                <div :key="textarea.id" class="table-list__textarea-cell">
                  <v-textarea
                    outlined
                    auto-grow
                    no-resize
                    rows="1"
                    :append-outer-icon="item.icon || 'mdi-none'"
                    v-model="item.label"
                    :hint="item.hint"
                    :success="!!item.success"
                    :error="!!item.error"
                    :error-messages="item.error"
                    @change="onTextareaChange(textarea, item)"
                  />
                </div>
              </template>
              <template
                v-for="custom in customs"
                v-slot:[`item.${custom.value}`]="{ item }"
              >
                {{ custom.itemValue(item) }}
              </template>
            </v-data-table>
          </v-card>
        </Container>
      </div>

      <ActionDialog
        :is-open.sync="dialog.show"
        :description="dialog.description"
        :items="dialog.items"
        :items-label="dialog.itemsLabel"
        :onConfirm="dialog.onConfirm"
      />
    </div>
    <div v-else>
      <Container>
        <div class="wrapper-loader">
          <Loader width="100" height="100" />
        </div>
      </Container>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import Component from "vue-class-component";
import ActionDialog from "@/components/dom/ActionDialog";
import ButtonBase from "@/components/dom/ButtonBase";
import Container from "@/components/dom/Container";
import Loader from "@/components/dom/Loader";
import Round from "@/components/dom/Round";
import Sortable from "sortablejs";
import TableAction from "@/components/table/TableAction";
import { Utility } from "@/lib/Utility";

@Component({
  components: {
    ActionDialog,
    ButtonBase,
    Container,
    Loader,
    Round,
    Sortable,
    TableAction,
  },
  directives: {
    sortable: {
      bind(el, binding, vnode) {
        if (binding.value) {
          const options = {
            animation: 150,
            onUpdate: function (event) {
              vnode.child.$emit("sorted", event);
            },
          };
          Sortable.create(el.getElementsByTagName("tbody")[0], options);
        }
      },
    },
  },
  props: {
    isReady: Boolean,
    ssp: {
      type: Boolean,
      default: false,
    },
    sspLength: {
      type: Number,
      default: -1,
    },
    searchFromQuery: {
      type: String,
      default: "",
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    searchVisible: {
      type: Boolean,
      default: true,
    },
    footerProps: {
      type: Object,
      // eslint-disable-next-line no-empty-function
      default: () => {},
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    bulkActions: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    currentFilters: {
      type: Object,
      default: null,
    },
  },
  watch: {
    tableOptions: function () {
      this.syncCurrentFilters();
    },
    selectedTableItems: function () {
      this.updateSelectedList();
    },
  },
})
// You can customize columns behaviors just by set some props in the headers
// For each header you CAN specify a custom type :
// custom --> If you want to have a custom value for the column (ie : string in uppercase)
// link --> If you want to have a button that redirect you to a link (specified by using the formatValue function) on the click
// image --> If you want to render an image (must return the path by using the itemValue function)
// date --> If you want to render a date with a pre-defined format
// datetime --> If you want to render a date with a pre-defined format with hours and minutes
// boolean --> If you want to render a RO boolean value with icon (you can customize the icons by using trueIcon and falseIcon props). The boolean value is taken from itemValue function
// switch --> If you want to render a slider that allow you to execute a function (onAction function) on the click
// action --> If you want to have a button that execute a method(onAction function) on the click
// textarea --> If you want to have a textarea containing the column value
// in all the columns you can specify also :
// isVisible --> function that contains visibility rule for the column (must return true/false)
// sortable --> it refers to thw whole table. Enable drag and drop. @sorted allow you to handle the event when the sort is complete (false default).
// itemsPerPage --> it refers to thw whole table. Specify how many items are visible per page (15 default).
// searchVisible --> it refers to thw whole table. Specify if the table must have the search bar ( true default)
// hideFooter --> it refers to thw whole table. Specify if the table must have the footer ( false default)
export default class TableList extends Vue {
  search = "";
  dialogIsOpen = false;
  selectedBulkAction = "";
  selectedFilter = "";
  selectedTableItems = [];
  dialog = {
    show: false,
    description: "",
    itemsLabel: "",
    items: [],
  };
  storeFilterName = "userActiveFilters";
  tableOptions = null;

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get activeFilters() {
    return this.$store?.state?.bulkStore?.filters?.userActiveFilters ?? [];
  }

  get activeFiltersGroups() {
    return [
      ...new Set(
        this.activeFilters?.map((f) => {
          return f.group;
        }) ?? []
      ),
    ];
  }

  get customs() {
    return this.getHeadersByType("custom");
  }
  get links() {
    return this.getHeadersByType("link");
  }
  get images() {
    return this.getHeadersByType("image");
  }
  get dates() {
    return this.getHeadersByType("date");
  }
  get datetimes() {
    return this.getHeadersByType("datetime");
  }
  get textareas() {
    return this.getHeadersByType("textarea");
  }
  get booleans() {
    return this.getHeadersByType("boolean");
  }
  get sliders() {
    return this.getHeadersByType("switch");
  }
  get actions() {
    return this.getHeadersByType("action");
  }
  get multipleActions() {
    return this.getHeadersByType("multipleactions");
  }

  get tableMultiselect() {
    return this.bulkActionsVisible;
  }

  get bulkActionsVisible() {
    return this.bulkActions?.length > 0;
  }
  get filtersVisible() {
    return this.filters?.length > 0;
  }
  get bulkActionsOptions() {
    return this.bulkActions
      ? this.bulkActions.map((x) => {
          return { text: x.text, value: x.text };
        })
      : [];
  }
  get filtersOptions() {
    return this.filters
      ? this.filters.map((x) => {
          return { text: x.text, value: x.text };
        })
      : [];
  }

  get itemsFiltered() {
    if (!this.items?.length) {
      return [];
    }
    if (!this.activeFilters) {
      return this.items;
    }
    if (this.ssp) {
      return this.items;
    }

    let finalList = this.items;
    const groupedFilters = Utility.groupByKey(
      this.activeFilters,
      "propToFilter"
    );
    Object.keys(groupedFilters).forEach((filterKey) => {
      const filterValues = groupedFilters[filterKey].map((f) =>
        f.value?.toLowerCase()
      );
      if (!finalList?.length) {
        return;
      }
      finalList = finalList.filter((item) => {
        const currValues = item[filterKey]?.toLowerCase().split(" | ");
        return Utility.intersect(filterValues, currValues).length > 0;
      });
    });

    return finalList;
  }

  updateSearch() {
    if (!this.ssp) {
      this.syncCurrentFilters();
    } else {
      Utility.debounce(() => {
        this.syncCurrentFilters();
      }, 750)();
    }
  }

  getHeadersByType(type) {
    return this.headers != undefined
      ? this.headers.filter((x) => x.type == type)
      : [];
  }

  applyBulkActions() {
    if (!this.selectedTableItems?.length) {
      alert(this.translations.labels.table_action_selectAnElement);
      return;
    }
    if (!this.selectedBulkAction) {
      alert(this.translations.labels.table_action_selectAnAction);
      return;
    }
    const selectedTableIds = this.selectedTableItems.map((x) => {
      return x.id;
    });
    const action = this.bulkActions.filter(
      (x) => x.text == this.selectedBulkAction
    )[0];
    if (
      action.processableEntitiesMax &&
      this.selectedTableItems.length > action.processableEntitiesMax
    ) {
      alert(
        this.translations.labels.table_action_maximumUsersProcessable.replace(
          "{maxNumber}",
          action.processableEntitiesMax
        )
      );
      return;
    }
    if (!action.items) {
      action.action(selectedTableIds);
      this.resetBulk();
      return;
    }

    this.dialog.description = action.description;
    this.dialog.itemsLabel = action.itemsLabel;
    this.dialog.items = action.items;
    this.dialog.onConfirm = this.dialogBulkActionConfirm;
    this.dialog.show = true;
  }

  dialogBulkActionConfirm(actionValue) {
    const action = this.bulkActions.filter(
      (x) => x.text == this.selectedBulkAction
    )[0];
    const selectedTableIds = this.selectedTableItems.map((x) => {
      return x.id;
    });

    action.action(selectedTableIds, actionValue);
    this.resetBulk();
  }

  applyFilters() {
    if (!this.selectedFilter) {
      alert(this.translations.labels.table_action_selectAnAction);
      return;
    }

    const filter = this.filters.filter((x) => x.text == this.selectedFilter)[0];
    this.dialog.description = filter.description;
    this.dialog.itemsLabel = filter.itemsLabel;
    this.dialog.items = filter.items;
    this.dialog.onConfirm = this.dialogFilterConfirm;
    this.dialog.show = true;
  }

  dialogFilterConfirm(filterValue, filterText) {
    const filter = this.filters.filter((x) => x.text == this.selectedFilter)[0];

    const newFilter = {
      text: filterText ?? "",
      value: filterValue,
      group: this.selectedFilter,
      propToFilter: filter.propToFilter,
    };

    if (
      this.activeFilters.filter(
        (f) =>
          f.propToFilter == newFilter.propToFilter && f.value == newFilter.value
      )?.length > 0
    ) {
      alert(this.translations.labels.table_filter_alreadyApplied);
      return;
    }

    this.$store.commit("ADD_TO_FILTER", {
      name: this.storeFilterName,
      value: newFilter,
    });

    this.resetBulk();

    this.syncCurrentFilters();
  }

  syncCurrentFilters() {
    const filterProps = {};
    this.activeFilters.forEach((filter) => {
      if (typeof filterProps[filter.propToFilter] === "undefined") {
        filterProps[filter.propToFilter] = [];
      }
      filterProps[filter.propToFilter].push(filter.value);
    });
    // we accept only one sort by; so we democratically take the first sortBy
    let orderBy = null;
    if (this.tableOptions.sortBy.length > 0) {
      const ascDesc = this.tableOptions.sortDesc[0] ? "DESC" : "ASC";
      orderBy = this.tableOptions.sortBy[0] + "|" + ascDesc;
    }
    const currFilters = {
      ...filterProps,
      search: this.search,
      orderBy: orderBy,
    };
    this.$emit("update:currentFilters", currFilters);

    this.$store.commit("SET_ROUTE_FILTER", {
      route: this.$route.path,
      filterValue: currFilters.search
    });

    if (this.ssp) {
      // if filters changed, reset page to 1
      if (!Utility.equalObjects(this.currentFilters, currFilters)) {
        this.tableOptions.page = 1;
      }
      // debounce the server request so it doesn't trigger at every keypress or too often
      Utility.debounce(() => {
        this.$emit("update:ssp", currFilters, this.tableOptions);
      }, 150)();
    }
  }

  updateSelectedList() {
    this.$emit("update:selecteditems", this.selectedTableItems);
  }

  removeFromActiveFilters(filter) {
    this.$store.commit("REMOVE_FROM_FILTER", {
      name: this.storeFilterName,
      value: filter,
    });
    this.syncCurrentFilters();
  }

  resetBulk() {
    this.selectedFilter = "";
    this.selectedBulkAction = "";
    this.selectedTableItems = [];
  }

  onTextareaChange(textarea, item) {
    if (typeof textarea.onChange !== "function") {
      return;
    }
    textarea.onChange(item, textarea);
  }

  formatDate(col, row, withMinutes) {
    if (col.formatValue != null && typeof col.formatValue === "function") {
      return col.formatValue(row);
    }
    if (withMinutes) return Utility.formatDateLocale(row[col.value]);
    return Utility.formatDate(row[col.value]);
  }

  onSortComplete(event) {
    this.$emit("sorted", event);
  }

  onAction(action, item) {
    action.onAction(item);
  }

  showAction(action, item) {
    if (typeof action.hide !== "function") {
      return true;
    }
    return !action.hide(action, item);
  }

  isVisible(item, row) {
    if (item.isVisible != null && typeof item.isVisible === "function") {
      return item.isVisible(row);
    }
    return true;
  }

  formatValue(col, row) {
    if (col.formatValue != null && typeof col.formatValue === "function") {
      return col.formatValue(row);
    }
    if (row[col.value] != undefined) return row[col.value];
    return null;
  }

  mounted() {
    if (this.searchFromQuery) {
      this.search = this.searchFromQuery;
    }

    let lastSearchValue = this.$store.state.navigationStore
         .lastFiltersByRoute[this.$route.path];
    if (lastSearchValue) {
      this.search = lastSearchValue ?? "";
    }
  }
}
</script>

<style lang="scss" scoped>
.table-list {
  &__textarea-cell {
    margin-top: 15px;
  }
}

.multiple-actions-container {
  justify-content: center;
  flex-direction: row;
  display: flex;
  gap: 10px;
}

.actions-container {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .bulk-actions-container {
    display: flex;
    width: 45%;
    margin-bottom: 10px;
    padding-right: 30px;

    .bulk-actions {
      width: 300px;
      margin-right: 15px;
    }
  }

  .filters-container {
    width: 45%;
    margin-bottom: 10px;

    .filters-selection {
      display: flex;

      .filters {
        width: 300px;
        margin-right: 15px;
      }
    }

    .active-filters {
      text-align: left;

      .group-filter {
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }

        .group-filter-name {
          margin-right: 10px;
        }
      }

      .chip-filter {
        margin-right: 5px;
      }
    }
  }
}

.search-container {
  width: 100%;
}
</style>
